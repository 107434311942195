import { Wish_LENGTH } from "../type"

//initializing state 
const initialState = {
    length:0
}

const wishLengthReducer= (state = initialState, action) => {
    

    switch (action.type) {
    
        case Wish_LENGTH: return {
            ...state,
            length: action.payload?.data?.length
        }
        default: return state
    }
}

export default wishLengthReducer;