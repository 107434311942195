import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Code from "../Code";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FetchAddressApi, EditAddressApi } from "../APIS/apis";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { ErrorToaster } from "../Toaster";
import Citycode from "../CityCode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid  #AA8C72",
  p: 4,
};

const EditAddressItem = ({
  open1,
  setOpen1,
  setAddress,
  getSelectedLanguage,
  Edit,
}) => {
  const { t } = useTranslation();

  const [selectCountryCode, setSelctCountryCode] = useState("+971");

  useEffect(() => {
    setInput({ addressId: Edit?.id, ...Edit });
  }, [Edit]);
  const [input, setInput] = useState({
    addressid: Edit?.id,
    name: "",
    city: "",
    state: "",
    street: "",
    landmark: "",
    code: "",
    mobile: "",
    phone_code: selectCountryCode,
    phone_country: "ae",
    address_type: "Home",
    default: 1,
  });

  const handleClose1 = () => setOpen1(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let Relove_Token = localStorage.getItem("Relove_Token");

    let formdata = {
      language: getSelectedLanguage,
    };
    e.preventDefault();
    if (
      input.name === "" ||
      input.address_type === "" ||
      input.city === "" ||
      input.landmark === "" ||
      input.mobile === ""
    ) {
      ErrorToaster("All field is requried");
    } else if (input?.code?.length >= "7" || input?.code === "") {
      ErrorToaster("Code is Less than 7 digit");
    } else if (input?.mobile?.length <= "7" || input?.mobile?.length >= "13") {
      ErrorToaster("Mobile Number 8 to 12");
    } else {
      EditAddressApi(
        Relove_Token,
        input,

        FetchAddressApi,
        formdata,
        (callback) => {
          setAddress(callback?.data);
        }
      );
      handleClose1(true);
    }
  };

  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="d-flex justify-content-between">
              <h5 style={{ marginBottom: "20px" }}>{t("EditAddress")}</h5>
              <IoMdClose onClick={handleClose1} fontSize={25} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">{t("Name")}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={input.name}
                    onChange={(e) =>
                      setInput({ ...input, name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("MobileNumber")}</label>
                <div className="row">
                  <div className="col-4">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectCountryCode}
                      onChange={(e) => setSelctCountryCode(e.target.value)}
                    >
                      <option selected>{selectCountryCode}</option>
                      {Code?.map((ele, i) => {
                        return (
                          <option key={i} value={ele.dial_code}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-8">
                    <input
                      type="Number"
                      className="form-control"
                      name="mobile"
                      value={input.mobile}
                      onChange={(e) =>
                        setInput({ ...input, mobile: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("State")}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={input.state}
                    onChange={(e) =>
                      setInput({ ...input, state: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("City")}</label>
                <div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="city"
                    value={input.city}
                    onChange={(e) =>
                      setInput({ ...input, city: e.target.value })
                    }
                  >
                    <option selected>{input.city}</option>
                    {Citycode?.map((ele, i) => {
                      return (
                        <option key={i} value={ele.code}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("Street")}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="street"
                    value={input.street}
                    onChange={(e) =>
                      setInput({ ...input, street: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("LandMark")}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="landmark"
                    value={input.landmark}
                    onChange={(e) =>
                      setInput({ ...input, landmark: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("PinCodeAddress")}</label>
                <div>
                  <input
                    type="Number"
                    className="form-control"
                    name="code"
                    value={input?.code}
                    onChange={(e) =>
                      setInput({ ...input, code: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t("AddressType")}</label>
                <div className="d-flex">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="address_type"
                      id="flexRadioDefault1"
                      onChange={(e) =>
                        setInput({ ...input, address_type: e.target.value })
                      }
                      checked={input.address_type === "Home" ? true : false}
                      value={"Home"}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      {t("AddressHome")}
                    </label>
                  </div>
                  <div class="form-check ms-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="address_type"
                      id="flexRadioDefault2"
                      onChange={(e) =>
                        setInput({ ...input, address_type: e.target.value })
                      }
                      checked={input.address_type === "Office" ? true : false}
                      value={"Office"}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {t("AddressHome")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-success"
                  type="submit"
                  style={{ backgroundColor: "#AA8C72", border: "none" }}
                >
                  {t("UpdateAddress")}
                </button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default EditAddressItem;
