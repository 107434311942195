import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";



export const CoupanFetch = (fromdata,callbackData)=>{

     let data=JSON.stringify(fromdata)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}discount-offers`,
      headers: {
        "Content-Type": "application/json",

      },
      data:data,
    };
  
    axios
      .request(config)
      .then((response) => {

        if (response.data.status === "failed") {
          ErrorToaster(response.data.message);
        } else {
          callbackData(response)

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  export const CoupanApply= (fromdata,token,setUpdateCartPage,updateCartPage)=>{

    let data=JSON.stringify(fromdata)
   let config = {
     method: "post",
     maxBodyLength: Infinity,
     url: `${baseUrl}coupon-apply`,
     headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${token}`,
     },
     data:data,
   };
 
   axios
     .request(config)
     .then((response) => {

       if (response.data.status === "failed") {
         ErrorToaster(response.data.message);
       } else {
        //  callbackData(response)
        setUpdateCartPage(!updateCartPage);
        SuccessToaster(response.data.message)


       }
     })
     .catch((error) => {
       console.log(error);
     });
 };
 
 export const CoupanRemove= (fromdata,token,setUpdateCartPage,updateCartPage)=>{

  let data=JSON.stringify(fromdata)
 let config = {
   method: "post",
   maxBodyLength: Infinity,
   url: `${baseUrl}coupon-remove`,
   headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${token}`,
   },
   data:data,
 };

 axios
   .request(config)
   .then((response) => {

     if (response.data.status === "failed") {
       ErrorToaster(response.data.message);
     } else {
      //  callbackData(response)
      setUpdateCartPage(!updateCartPage);
      SuccessToaster(response.data.message)


     }
   })
   .catch((error) => {
     console.log(error);
   });
};
