import React from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  
    width: 400,
  
    bgcolor: "background.paper",
    border: "2px solid #000",
  
    p: 3,
  };

const ReviewmodelImage = ({open7, setOpen7,modelImage} ) => {
  const handleClose7 = () => setOpen7(false);

  return (
    <>
      <Modal
        open={open7}
        onClose={handleClose7}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup" >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <>
              <div className="d-flex justify-content-between">
                <h5>Image</h5>
                <IoMdClose onClick={handleClose7} style={{fontSize:"25px"}} />
              </div>
              <div className='' style={{height:""}}>
              <div className='mb-3'>
                <img src={modelImage} alt="done" style={{width:"100%",height:"200px"}}/>
                </div>

              </div>
             
              
            </>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default ReviewmodelImage