import React,{useState} from "react";
import "./../Styles/topsale.css";
import { useTranslation } from "react-i18next";

function AllProductHeading({allProducts, handleClickHeader,selectItem,setSelectItem}) {
  const { t } = useTranslation();

  return (
    <div className="topsale">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 heading-topsale text-center">
            <h2>{t('Topmonthall')} <span>{t('TopSellerall')}</span></h2>
            <span className="topsale-dot"></span>
             <span className="topsale-line"></span>
            <p className="m-0 " >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="container">
         <div className="row">
            <div className="topsale-tab-list d-flex justify-content-center">
                <ul>
                    <li onClick={()=> handleClickHeader('All')} 
                      className={selectItem==='All'?'select-topsale-item':''} >{t('Alltop')}</li>
                    {
                      allProducts?.map((item,i)=>{
                          return <li key={i} onClick={()=> handleClickHeader(item.id)} 
                           className={selectItem===item.id?'select-topsale-item':''} >{item.collectionName}</li>
                      })
                    }
           
                </ul>
            </div>
         </div>
      </div>
    </div>
  );
}

export default AllProductHeading;
