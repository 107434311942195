import React, { useEffect, useState, useLayoutEffect } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./../Styles/topsaleproduct.css";
import { FetchWishlistApi } from "../APIS/WishlistApis";
import { useTranslation } from "react-i18next";

import LoginModal from "../Modals/LoginModal";


function AllProducts({ allProducts, handleWisthlist,getSelectedLanguage,getUserAuthenticationRedux }) {
  const navigation = useNavigate();
  const [allWishtDate, setAllWishData] = useState([]);
  const { t } = useTranslation();
  let language=localStorage.getItem("language")

  const [showLoginModal, setShowLoginModal] = useState(false);

  useLayoutEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage
    };
    FetchWishlistApi(Relove_Token, formdata, (callbackData) => {
      setAllWishData(callbackData.data);

    });
  }, [handleWisthlist]);
  
  return (
    <div className="topsaleproduct">
      <div className="container">

      <LoginModal setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}/>
        <div className="allproducts-product-main ">
          {allProducts &&
            allProducts?.map((item, i) => {
              return (
                <div key={i}>
                  <h4 className="all-product-sub-heading">
                    {item.collectionName}
                  </h4>
                  <ul className=" d-flex">
                    {item?.products?.map((ele, i) => {
                      return (
                        <li key={i} className="mt-3">
                          <div className="allproducts-background-div text-center">
                          
                            <h6
                              onClick={() => {
                           let Relove_Token = localStorage.getItem("Relove_Token");

                                if(!Relove_Token){
                                  setShowLoginModal(true)
                                }
                                else{
                                handleWisthlist(ele.id);}
                              }}
                              className="text-end"
                            >
                              {allWishtDate.findIndex(
                                ({ product_id }) =>  +product_id === +ele.id
                              ) === -1 ? (
                                <AiOutlineHeart />
                              ) : (
                                <AiFillHeart color="black" />
                              )}
                            </h6>
                            <span className="text-center">
                              {ele?.offerDiscount}% OFF
                            </span>
                          </div>
                          <Link to={`/product/${ele.id}`}>
                            <img src={ele.featured_image} alt="" />

                            <h4 className="tag-name-product">{t('PureCotton')}</h4>
                            <h5 className="text-start">
                              {ele?.name?.slice(0, 20)}...
                            </h5>
                            <div className="d-flex justify-content-between align-items-center">
                              <p>
                                {ele?.sale_price} {t('AED')}{" "}
                                <span className="discount-price">
                                  ({ele?.base_price}) {t('AED')}
                                </span>
                              </p>
                            </div>
                          </Link>
                          <div className="add-to-cart-button mt-3">
                            <button
                              onClick={() => {
                                navigation(`/product/${ele.id}`);
                              }}
                            >
                             {t('AddToCart')}
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
    getUserAuthenticationRedux: state.userAuthentication.data,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AllProducts);
