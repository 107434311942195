import React from 'react'
import Home from '../Home/Home'
import ProductPage from '../ProductPage/ProductPage';
import CartPage from '../Pages/CartPage';
import CategoryPage from '../ProductPage/CategoryPage';
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';
import ProfilePage from '../Pages/ProfilePage';
import WishlistPage from '../Pages/WishlistPage';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import TermsonUse from '../Pages/TermsonUse';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import AddrerssItem from '../Pages/AddrerssItem';
import Contactuspages from '../Pages/Contactuspages';
import Forgetpassword from '../Auth/Forgetpassword';
import Resetpassword from '../Auth/Resetpassword';
import ReturnPolicy from '../Pages/Return&Policy';
import AboutUs from '../Pages/AboutUs';
import Singlebuy from '../Pages/Singlebuy';


const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path:'/product/:slug',
      element:<ProductPage/>

    },
    {
      path:'/cart',
      element:<CartPage/>

    },
    {
      path:'/category/:slug/:slug/:slug',
      element:<CategoryPage/>

    },
    {
      path:'/login',
      element:<Login/>
    },
    {
      path:'/signup',
      element:<Signup/>
    },
    {
      path:'/profile',
      element:<ProfilePage/>
    },
    {
      path:'/wishlist',
      element:<WishlistPage />
    },
    {
      path:'/termsuse',
      element:<TermsonUse />
    },
    {
      path:'/privacypolicy',
      element:<PrivacyPolicy/>
    },{
      path:'/Relove/checkout/payment',
      element:<AddrerssItem/>
    },
    {
      path:"/contact" , element:<Contactuspages/>
    },
    {
      path:"/forget-password" , element:<Forgetpassword/>
    },
    {
      path:"/Reset-password",element:<Resetpassword/>
    },
    {
      path:"/returnpolicy",element:<ReturnPolicy/>
    },
    {path:"/aboutus",element:<AboutUs/>},
    {path:"/singlebuynow",element:<Singlebuy/>}

  ]);

function Routes() {
  return (
    <div>
     <RouterProvider router={router} />
    </div>
  )
}

export default Routes
