import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";
export const SocialLoginGoogle = (formData, navigation, setUserAuthenticationRedux) => {

    let data = JSON.stringify(formData);
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}social-login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(response)
        localStorage.setItem("Relove_Token", response.data.data.token);
        setUserAuthenticationRedux(response.data.data.token?.replaceAll('"', ""));
        SuccessToaster(response?.data?.message);
        navigation("/");
      })
      .catch((error) => {
        let err = error?.response?.data?.message;
        if (err.length > 0) {
          console.log(error?.response?.data?.message);
          ErrorToaster(error?.response?.data?.message[0]);
        } else {
          ErrorToaster(error?.response?.data?.message);
        }
        console.log(error, "error");
      });
  };