import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import "./../Styles/footer.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function Footer() {
  const navigation = useNavigate();
  const { t } = useTranslation();


  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-first-section">
                    <ul>
                      <li>{t('UsefulLinks')}</li>
                      <li onClick={()=>navigation("/login")}>{t('LoginFooter')}</li>
                      <li onClick={()=>navigation("/cart")}>{t('MyCartFooter')}</li>
                      <li>{t('RatingFooter')}</li>
                      <li onClick={()=>{ navigation("/Relove/checkout/payment")
                      window.scroll(0,0)
                      }}>{t('CheckoutFooter')}</li>
                      <li>{t('SiteMapFooter')}</li>
                      <li>{t('ExchangeFooter')}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer-first-section">
                    <ul>
                      <li>{t('OurShop')}</li>
                      <li onClick={()=>{ navigation("/aboutus")
                      window.scroll(0,0)
                      }}>{t("AboutUs")}</li>
                      <li onClick={()=>{window.scroll(0,0)}}>{t('MenWearFooter')}</li>
                    

                      <li onClick={()=>{window.scroll(0,0)}}>{t('WomenWearFooter')}</li>
                      <li onClick={()=>{window.scroll(0,0)}}>{t('kidsfooter')}</li>
                      <li onClick={()=>{window.scroll(0,0)}}> {t('winterFooter')}</li>
                      <li onClick={()=>{window.scroll(0,0)}}>{t('beautyfooter')}</li>
                      <li onClick={()=>{window.scroll(0,0)}}>{t('partyfooter')}</li>
                      <li>{t('salefooter')}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer-first-section">
                    <ul>
                      <li>{t('Customerfooter')}</li>
                      <li onClick={()=>{navigation("/contact")
                       window.scroll(0,0)
                      }}>{t('contactfooter')}</li>
                      <li onClick={()=>{navigation("/termsuse")
                       window.scroll(0,0)
                      }}>{t('Termsfooter')}</li>
                      <li >{t('Trackfooter')}</li>
                      <li onClick={()=>{navigation("/returnpolicy")
                         window.scroll(0,0)
                      }}>{t("Return&Policy")}</li>
                      <li>{t('Cancellationfooter')}</li>
                      <li>{t('Retunfooter')}</li>
                      <li onClick={()=>{navigation("/privacypolicy")
                       window.scroll(0,0)
                      }}>{t('Privacyfooter')}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="footer-second-section">
                <ul>
                  <li>{t('Experiencefooter')}</li>
                  <li className="store-img">
                    <img src="/img/store1.png" alt="image" />
                    <img src="/img/google.png" alt="image" />
                  </li>
                  <li>{t('Keepfooter')}</li>
                  <li className="footer-social-icon">
                    <FaFacebookF />
                    <AiFillInstagram />
                    <BsTwitter />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-white ">
              <span>{t('Policefooter')} </span>
              <span>/</span>
              <span>{t('Conditionsfooter')}</span>
            </div>
            <div className="col-md-5 text-white">
              <span>{t('cyberfooter')}</span>
            </div>
            <div className="col-md-3 d-flex justify-content-between">
              <div className="payment-image">
              <img src="/payment-images/paypal.png" alt="img" />
              </div>
              <div className="payment-image">
              <img src="/payment-images/mastercard.png" alt="img" />
              </div>
             <div className="payment-image">
             <img src="/payment-images/visa.png" alt="img" />
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
