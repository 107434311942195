import React from 'react'
import './../Styles/banner.css'
import { useTranslation } from "react-i18next";


function Banner() {

  const { t } = useTranslation();

  return (
    <>
      <div className="container-fluid">
       <div className="bannerpage">
         <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 d-flex justify-content-center align-items-center">
                   <div className="banner-1">
                   <h4>{t('designerbanner')}</h4>
                   <h2>{t('WomenCollection')}</h2>
                   <p id="loerm">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid eveniet adipisci inventore est. Illo amet dolor eaque vitae. In, similique!</p>
                   <button>{t('ShopNowbanner')}</button>
                   </div>
                </div>
                <div className="col-md-7">
                    <div className="banner-2">

                    </div>
                </div>
            </div>
         </div>
      </div>
      </div>
    </>
  )
}

export default Banner
