import React, { useState, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineMail, AiFillEye, AiFillEyeInvisible, AiOutlineLock } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CartloginApi } from "../APIS/cartApis";
import { setUserAuthentication } from "../Redux/actions";
import { ErrorToaster } from "../Toaster";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";


function LoginModal({ setShowLoginModal, showLoginModal, setUserAuthenticationRedux}) {
    const navigation = useNavigate();
  const handleClose = () => setShowLoginModal(false);
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailrror] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailrror("Please Enter The Email");
      ErrorToaster("Please Enter The Email");
    } else if (password === "") {
      setPasswordError("Please Enter The Password");
      ErrorToaster("Please Enter The Password");
    } else {
     CartloginApi(email, password,setShowLoginModal, setUserAuthenticationRedux);
    }
  };
  useLayoutEffect(() => {
   
  }, []);
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLoginModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="login_title">
          {t('WelcomeRelove')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="login_heading">{t('FillDetailslogin')}</h5>
         
          <div className="login_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiOutlineMail />
              </InputGroup.Text>
              <Form.Control
                style={{
                    borderColor: emailError ? "red" : "",
                    color: emailError ? "red" : "",
                 }}
                placeholder={t('EmailLogin')}
                aria-label="email"
                aria-describedby="basic-addon1"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
              />
            </InputGroup>
          </div>
      

          <div className="login_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiOutlineLock />
              </InputGroup.Text>
              <Form.Control
                style={{
                    borderColor: passwordError ? "red" : "",
                    color: passwordError ? "red" : "",
                }}
                placeholder={t('PasswordLogin')}
                aria-label="phone"
                aria-describedby="basic-addon1"
                type={showPassword?'text':'password'}
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
              />
                <InputGroup.Text id="basic-addon1">
                {
                    showPassword
                    ?
                    <AiFillEyeInvisible onClick={()=>setShowPassword(!showPassword)} />
                    :
                    <AiFillEye onClick={()=>setShowPassword(!showPassword)} />
                }
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="login_button" onClick={handleLogin}>
            {t('Login')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setUserAuthenticationRedux: (parameter) => {
        dispatch(setUserAuthentication(parameter));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
  
