import React from 'react';

import Topbar from '../Sections/Topbar'
import { useTranslation } from "react-i18next";

import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';


const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Topbar />
      <Navbar />
      <div style={{borderTop:"2px solid #AA8C72"}}></div>

       <div>
        <div className='container my-5' style={{minHeight:"600px"}}>
            <h6 className='fw-bold'>{t('NoticePolicy')}</h6>
            <p>{t('NoticeData')}</p>
            <h6 className='fw-bold'>{t('PurposePolicy')}</h6>
            <p>{t('PurposeData')}</p>
            <h6 className='fw-bold'>{t('ConsentPolicy')}</h6>
            <p>{t('ConsentData')}</p>
            <h6 className='fw-bold'>{t('SecurityPolicy')}</h6>
            <p>{t('SecurityData')}</p>
            <h6 className='fw-bold'>{t('DisclosurePolicy')}</h6>
            <p>{t('DisclosureData')}</p>
            <h6 className='fw-bold'>{t('AccessPolicy')}</h6>
            <p>{t('AccessData')}</p>
        </div>
       </div> 
       <Footer/>
    </>
  )
}

export default PrivacyPolicy