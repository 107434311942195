import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";


export const paymentApi=(formdata)=>{
    let data = JSON.stringify(formdata);
    console.log("data",data)
    let config={
        method:"post",
       maxBodyLength: Infinity,
       mode:"core",
        url:"https://api.test.paymennt.com/mer/v2.0/checkout/web",
        headers: {
            "Content-Type": "application/json",
            "Content-Length":"<calculated when request is sent>",
            "Host":"<calculated when request is sent>",
            "X-Paymennt-Api-Key": "190302f74a2efdcc",
            "X-Paymennt-Api-Secret": "mer_5ee0cd6c65812712715599d25762930752e8e07db002046187802cf58e7f2e5f",
            "Connection":"keep-alive",
           
       },
          data: data,
    }
    axios.request(config)
    .then((response)=>{
        console.log(response)
    })
    .catch((error)=>{
        console.log(error);
    })
}