import { User_Deatils } from "../type";

//initializing state
const initialState = {
  data: null,
};

const userDeaitlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case User_Deatils:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default userDeaitlsReducer;
