import React, { useLayoutEffect, useState, useEffect } from "react";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import ParentCategory from "../Sections/ParentCategory";
import Banner from "../Sections/Banner";
import FashionSale from "../Sections/FashionSale";
import AllProductHeading from "../Sections/AllProductHeading";
import AllProducts from "../Sections/AllProducts";
import BannerMiddle from "../Sections/BannerMiddle";
import TodayPopularSale from "../Sections/TodayPopularSale";
import Footer from "../Sections/Footer";
import { connect } from "react-redux";
import { getAllProductsHomeApi } from "../APIS/apis";
import { AddWishlistApi, FetchWishlistApi,RemoveWishlistApi } from "../APIS/WishlistApis";
import { setAllProducts, setWishLength } from "../Redux/actions";
// import{FetchCartApi} from "../APIS/cartApis"


function Home({
  setAllProductsRedux,
  getAllProductsRedux,
  getSelectedLanguage,
  getWishLengthRedux,
  setUpdateProductSetWishLength,
  getUserAuthenticationRedux
}) {
  const [selectItem, setSelectItem] = useState("All");
  const [allHeadings, setAllHeadings] = useState([]);
  const [updateProductPage, setUpdateProductPage] = useState(false);
const language=localStorage.getItem("language")
const [allWishtDate, setAllWishDatap] = useState([]);
// const[value,setValue]=useState([])

  useLayoutEffect(() => {
    fetchAllProducts();
  }, []);

  const fetchAllProducts = () => {
    getAllProductsHomeApi(language,(data) => {
      setAllProductsRedux(data);
      setAllHeadings(data);
    });
  };

  const handleClickHeader = (productID) => {
    if (productID === "All") {
      fetchAllProducts();
      setSelectItem(productID);
    } else {
      setSelectItem(productID);
      getAllProductsHomeApi(language,(data) => {
        data.forEach((ele, i) => {
          if (ele.id === productID) {
            setAllProductsRedux([ele]);
          }
        });
      });
    }
  };
  const handleWisthlist = (productId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
      productId: productId,
    };
    
   
    
    AddWishlistApi(
      Relove_Token,
      formdata,
      updateProductPage,
      setUpdateProductPage,
      FetchWishlistApi,
      setUpdateProductSetWishLength
    );

  };

  return (
    <div>
      <Topbar />
      <Navbar />
      <Banner />
      <ParentCategory getSelectedLanguage={getSelectedLanguage}/>
      <FashionSale />
      <AllProductHeading
        allProducts={allHeadings}
        handleClickHeader={handleClickHeader}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <AllProducts
        allProducts={getAllProductsRedux}
        handleWisthlist={handleWisthlist}

       
      />
      <BannerMiddle />
      <TodayPopularSale getSelectedLanguage={getSelectedLanguage}/>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getAllProductsRedux: state.allProducts.products,
    getSelectedLanguage: state.language.language,
    getWishLengthRedux: state.wishlength.length,
    getUserAuthenticationRedux: state.userAuthentication.data,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllProductsRedux: (parameter) => {
      dispatch(setAllProducts(parameter));
    },
    setUpdateProductSetWishLength: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
