import React, { useEffect, useState } from "react";
import Navbar from "../Sections/Navbar";
import Banner from "../Sections/Banner";
import Footer from "../Sections/Footer";
import Filters from "./Filters";
import { AiOutlineHeart } from "react-icons/ai";
import { useNavigate,NavLink } from "react-router-dom";
import { fetchCategoryProducts } from "../APIS/apis";
import "../Styles/categorypage.css";
import { useTranslation } from "react-i18next";


function CategoryPage() {
  const[minprice,setMinprice]=useState(0)
  const[maxprice,setMaxprice]=useState(500000)
const [rating,setRating]=useState(0)
const { t } = useTranslation();

  const id = window.location.pathname.split("/")[2];
  const CategoryName = window.location.pathname.split("/")[3];
  const navigation = useNavigate();
  const [showHeartIcon, setShowHeartIcon] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const[iteam,setIteam]=useState([])
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  // const records = review.reviews.slice(firstIndex, lastIndex);
  const npage = Math.ceil(iteam.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const language=localStorage.getItem("language")
  const [showLoginModal, setShowLoginModal] = useState(false);

  // console.log(language)
  useEffect(() => {
    let formdata={
      page: currentPage,
      language: language,
      categoryId: id,
      orderBy:"id",
      maxPrice:maxprice,
      minPrice:minprice,
      minRating:rating,

    }

    fetchCategoryProducts(formdata, (data) => {

      setProducts(data?.data?.categoryProducts);
      setIteam(data?.data)

    });
  }, [currentPage,maxprice,minprice,rating,id]);

  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCpage = (n) => {
    setCurrentPage(n);
    window.scroll(0,0)
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };
 

const[show,setShow]=useState(false)

  return (
    <div>
      <Navbar /> 
      <Banner />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-md-block d-none"><Filters setMaxprice={setMaxprice} setMinprice={setMinprice} minprice={minprice} maxprice={maxprice} setRating={setRating} /></div>
          <div className="d-md-none d-flex mt-5 justify-content-end" style={{position:"relative"}}>
            <button type="button" className="btn btn-outline-success" id="btn-outline-success" style={{borderColor:"black",color:"black",}} onClick={()=>setShow(true)}>{t('Filter')}</button>
           
          </div>
          {show===true? <>
            <div className="category-filter">
            <Filters setMaxprice={setMaxprice} setMinprice={setMinprice} minprice={minprice} maxprice={maxprice} setRating={setRating} setShow={setShow}/>
            </div>
           </>:""}
          <div className="col-md-9">
          <h1 className="category-page-route-heading" >{CategoryName} {" "} <h2>{t('ShowingResult')} {(products?.length)}</h2></h1>
            <div className="category-product-main">

              <ul>
                {products &&
                  products?.map((item, i) => {

                    return (
                      <li
                        onMouseEnter={() => setShowHeartIcon(i)}
                        key={i}
                        onClick={() => {
                          navigation(`/product/${item.id}`);
                        }}
                      >
                        <div
                          className="category-background-div text-center"
                          style={{
                            backgroundImage: `url(${item.featured_image})`,
                          }}
                        >
                          <div
                            className={`text-end ${
                              showHeartIcon === i ? "d-block" : "d-none"
                            } `}
                          >
                          
                            {" "}
                            <AiOutlineHeart />
                          </div>
                          <span className="text-center">
                            {item?.offerDiscount}% {t('OFF')}
                          </span>
                        </div>
                        <h6>{t('PureCotton')}</h6>
                        <h5 className={`text-start`}>
                          {item?.name?.slice(0, 20)}...
                        </h5>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>
                            {item?.sale_price} {t('AED')}{" "}
                            <span className="discount-price-category">
                              ({item?.base_price}) {t('AED')}
                            </span>
                          </p>
                        </div>

                        <div className="category-on-hover-card">
                          <h6>{t('PureCotton')}</h6>
                          <h5 className={`text-center`}>
                            {item?.name?.slice(0, 20)}...
                          </h5>
                          <div className="d-flex justify-content-center align-items-center">
                            <p className="text-center">
                              {item?.sale_price} {t('AED')}{" "}
                              <span className="discount-price-category text-center ">
                                ({item?.base_price}) {t('AED')}
                              </span>
                            </p>
                          </div>
                          <button>{t('AddToCart')}</button>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <nav>
        <ul className="pagination d-flex justify-content-end">
          <li className="page-item">
            <NavLink
              to=""
              className={`page-link${currentPage === 1 ? "back" : ""}`}
              onClick={perPage}
            >
              {currentPage === 1 ? "" : <>{t('PrevPage')}</>}{" "}
            </NavLink>
          </li>
          {numbers.map((n, i) => (
            <li
              className={`page-item ${currentPage === n ? "active" : ""}`}
              key={i}
            >
              <NavLink
                to=""
                className="page-link"
                onClick={() => changeCpage(n)}
              >
                {n}
              </NavLink>
            </li>
          ))}
          <li className="page-item">
            <NavLink
              to=""
              className={`page-link${
                currentPage === numbers.length ? "next" : ""
              }`}
              onClick={nextPage}
            >
              {currentPage === numbers.length ? "" : <>{t('NextPage')}</>}
            </NavLink>
          </li>
        </ul>
      </nav>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CategoryPage;
