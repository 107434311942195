import React, { useLayoutEffect, useState } from "react";
import { AiOutlinePlus, AiTwotoneDelete } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import Footer from "../Sections/Footer";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import {
  FetchCartApi,
  AddToCartApi,
  UpdateCartApi,
  deleteCartApi,
} from "../APIS/cartApis";
import { FetchWishlistApi, RemoveWishlistApi } from "../APIS/WishlistApis";
import { connect } from "react-redux";
import { setWishLength } from "../Redux/actions";
import "../Styles/cartpage.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function WishlistPage({ getSelectedLanguage, setUpdateProductSetWishLength }) {
  const [allWishtDate, setAllWishData] = useState([]);
  const [updateWishtPage, setUpdateWishPage] = useState(false);
  const [updateProductGetWishLength, setUpdateProductGetWishLength] = useState(
    false
  );
  const [updateProductGetCartLength, setUpdateProductGetCartLength] = useState(
    false
  );
  const { t } = useTranslation();

  const handleDeleteWish = (cartId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      productId: cartId,
      language: getSelectedLanguage,
    };
    RemoveWishlistApi(
      Relove_Token,
      formdata,
      updateWishtPage,
      setUpdateWishPage
    );
  };

  useLayoutEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchWishlistApi(Relove_Token, formdata, (callbackData) => {
      setAllWishData(callbackData);
      setUpdateProductSetWishLength(callbackData);
    });
  }, [updateWishtPage]);

  const navigation = useNavigate();
  const handleVeiw = (id) => {
    navigation(`/product/${id}`);
  };
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container-fluid  main-cart">
        <div className="container p-0">
          <div className="second-cart pt-3">
            <h5 className="home-card">{t('Homecart')}{">"} {t('YourWhishlist')}</h5>
            <p>
             {t('pleasefillwhishlist')}
            </p>
            <p>
             {t('AlreadyRegistedCart')} <span>{t('PleaseloginCart')}</span>
            </p>
          </div>
          <div className="row header my-4 d-sm-flex">
            <div className="col-4">
              <h5 className="text-center">{t('ProducetCart')}</h5>
            </div>
            <div className="col-2">
              <h5 className="text-center">{t('PriceCart')}</h5>
            </div>
            <div className="col-2">
              <h5 className="text-center">{t('ViewItem')}</h5>
            </div>
            <div className="col-2">
              <h5 className="text-center">{t('RemoveCart')}</h5>
            </div>
          </div>

          <div className="container" style={{minHeight:"500px"}}>
            {allWishtDate?.data?.length !== 0 ? (
              allWishtDate?.data?.map((item, i) => {

                return (
                  <>
                    <div className="row mt-4 shadow-sm h-25 d-flex justify-content-between ">
                      <div className="col-sm-4 col-12 p-3 d-flex justify-content-between align-content-center left">
                        <div className="row  d-flex align-items-center">
                          <div className=" col-sm-8 col-12">
                            <div className="left-men-image">
                              <img
                                src={item?.featured_image}
                                alt="image"
                                className="men-image"
                                onClick={() => handleVeiw(item.product_id)}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-12">
                            <div className="left-men-image-text d-flex flex-column justify-content-center" style={{width:"130px"}}>
                              <h5 className="mt-1 p-0 men-kurta">
                               {t('MenCart')}
                              </h5>
                              <p className="" style={{overflow:"auto",overflowX:"hidden",height:"50px" }}>{item?.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-2 p-0 d-flex justify-content-sm-center align-items-center left-center">
                        <div
                          className="d-flex align-items-center"
                          id="handlecart"
                        >
                          <span className="d-sm-none handle-sm-span">
                           {t('Pricesmallcart')}
                          </span>
                          <span className="d-sm-none fs-bold ms-5">:</span>
                          <h6 className="m-0 aed">{t('AED')} {item?.sale_price}</h6>
                        </div>
                      </div>
                      <div className="col-sm-2 d-flex justify-content-sm-around align-items-center center ms-0">
                        <div
                          className="d-flex align-items-center"
                          id="handlecart"
                        >
                          <span className="d-sm-none handle-sm-span">{t('ViewWhishlist')}</span>
                          <span className="d-sm-none fs-bold ms-5">:</span>
                          <button
                            className="d-flex justify-content-around w-75 h-25 center-number align-items-center"
                            style={{ border: "none", minWidth: "100px" }}
                            onClick={() => handleVeiw(item.product_id)}
                          >
                            {t('ViewItem')}
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-2 delete_cart d-flex justify-content-sm-center align-items-center right-center">
                        <div
                          className="d-flex align-items-center"
                          id="handlecart"
                        >
                          <span className="d-sm-none handle-sm-span">
                          {t('Removesmallcart')}
                          </span>
                          <span className="d-sm-none fs-bold ms-3">:</span>
                          <h6 className="m-0 free ms-2">
                            <AiTwotoneDelete
                              onClick={() => handleDeleteWish(item.product_id)}
                            />
                          </h6>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="my-5 d-flex justify-content-center align-items-center Emtaycart">
                <h1>{t('EmptyWishlist')}</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateProductSetWishLength: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPage);
