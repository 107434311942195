import React from 'react'
import './../Styles/bannermiddle.css'
import { useTranslation } from "react-i18next";



function BannerMiddle() {
  const { t } = useTranslation();

  return (
    <>
      <div className="container-fluid">
       <div className="bannermiddlepage">
         <div className="container-fluid">
            <div className="row">
                <div className="col-md-5 d-flex justify-content-center align-items-center">
                   <div className="bannermiddle-1">
                   <h4>{t('designerbanner')}</h4>
                   <h2>{t('Clothingmiddlebanner')} </h2>
                   <p id="middlebanner">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid eveniet adipisci inventore est. Illo amet dolor eaque vitae. In, similique!</p>
                   <button>{t('Shopfashion')}</button>
                   </div>
                </div>
                <div className="col-md-7">
                    <div className="banner-2">

                    </div>
                </div>
            </div>
         </div>
      </div>
      </div>
    </>
  )
}

export default BannerMiddle
