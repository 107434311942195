import React, { useEffect, useState } from "react";
import OrderDetails from "./../Styles/OrderDetails.css";
import { orderIteamFetch, orderCancelApi } from "../APIS/apis";
import TrackModel from "./TrackModel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import{TrackOrderAPi} from "../APIS/ShippingApi"



const OrderDetail = ({
  getSingleAddress,
  getSelectedLanguage,
  orderId,
  order_id,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigate();


  const [orderDetail, setOrderDetail] = useState([]);
    const variations=orderDetail.variation;

  const [orderShipping, setOrderShipping] = useState([]);

  const [open, setOpen] = useState();
  const [ordercancel, setOrdercancel] = useState("");
  const[trackordernumber,setTrackordernumber]=useState()
  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    let fromdata = {
      language: getSelectedLanguage,
      orderId: orderId,
      orderNumber: order_id,
    };

    orderIteamFetch(Relove_Token, fromdata, (callback) => {
      // console.log(callback,"123456789")
      setOrderDetail(callback.data.orders);
      setOrderShipping(callback.data.orders.addressShipping);
      setOrdercancel(callback?.data?.orders?.status);

    });
  }, []);



 
  const handleTrack = () => {
    const awdNumber=orderDetail?.awd_number;
    let Relove_Token = localStorage.getItem("Relove_Token");
    console.log(awdNumber)
    let fromdata={
      awd_number:awdNumber,
    }
    TrackOrderAPi(Relove_Token,fromdata,(callback)=>{
      setTrackordernumber(callback)
      setOpen(true);
    })
    

  };

  const orderCancel = () => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let Data = {
      language: getSelectedLanguage,
      orderId: orderId,
      orderNumber: order_id,
    };

    let formdata = {
      orderId: orderDetail.order_id,
      orderItemId: [orderDetail.product_id],
    };
    orderCancelApi(Relove_Token, formdata, orderIteamFetch, Data,navigation)

    
  };



  return (
    <div className="orderheading">
      <div className="order-adders">
        <h4 className="fw-bold">{t("DeliveryOrderADD")}</h4>
        <h6 className="fw-bold">{orderShipping.name}</h6>
        <div className="d-flex">
          <span className="fw-bold">
            {t("Address")}({orderShipping.address_type})
          </span>
          <span className="fw-bold ms-2 me-1">:</span>
          <span className="">
            <p className="mb-0">{orderShipping.street}</p>
            <span className="ms-1 d-inline block d-block">
              {orderShipping.landmark}
            </span>
            <span className="ms-1">{orderShipping.city}</span>
            <span className="ms-1 d-inline block d-block">
              {orderShipping.state}
            </span>
            <span className="ms-1 ">{orderShipping.code}</span>
          </span>{" "}
        </div>
        <div className="d-flex">
          <h6 className="fw-bold mb-0">{t("PhoneNumber")}</h6>
          <span className="fw-bold ms-3 me-1">:</span>
          <p className="mb-0">
            <span>{orderShipping.mobile}</span>
          </p>
        </div>
        <div className="d-flex">
          <p className="fw-bold">{t("OrderTrackby")} </p>
          <p className="ms-2">7014890000</p>
        </div>
      </div>
      <div className="order-adders ">
        <div className="row d-flex">
          <div className="col-md-3 col-12">
            <div className="order-adders-image">
              <img src={orderDetail.productImage} alt="pro" />
            </div>
          </div>
          <div className="col-md-9 col-12 mt-md-0 mt-3">
            <p className="fw-bold"> {orderDetail.productName}</p>

            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("Color")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{variations?variations.variations[0].variationName:"Red"}</p>
              

            </div>
            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("Size")}
                <span className="ms-3 me-2">:</span>
              </p>
              <p>{variations?variations.variations[1].variationName:"L"}</p>
            </div>
            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("Seller")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.vendorName}</p>
            </div>

            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("Price")}
                <span className="ms-3 me-1">:</span>
              </p>
              <p>{orderDetail.order_amount}</p>
            </div>
            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("Quantity")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.quantity}</p>
            </div>
            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("BasePrice")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.base_price}</p>
            </div>

            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("OrderId")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.order_id}</p>
            </div>
            <div className="d-flex delivery-address-producet">
              <p className="fw-bold">
                {t("OrderNumber")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.order_number}</p>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {orderDetail.status === 3 ? (
            <>
              <div className="d-flex justify-content-center">
                <button className="btn btn-danger">{t("OrderCancel")}</button>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-success trackbutton-cancel"
                  onClick={orderCancel}
                >
                  {t("OrderCancel")}
                </button>
                <button
                  className="btn btn-success trackbutton-cancel ms-3"
                  onClick={handleTrack}
                >
                  {t("OrderTrack")}
                </button>
              </div>
            </>
          )}
          {/* <div className="d-flex justify-content-center">
          <button className="btn btn-success trackbutton-cancel" onClick={orderCancel}>{t('OrderCancel')}</button>
          <button className="btn btn-success trackbutton-cancel ms-3" onClick={handleTrack}>{t('TrackOrder')}</button>
          </div> */}
        </div>
        <TrackModel open={open} setOpen={setOpen} trackordernumber={trackordernumber} />
      </div>
    </div>
  );
};

export default OrderDetail;
