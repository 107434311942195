import React, { useState, useEffect, useRef } from "react";
import Navbar from "./../Sections/Navbar";
import Topbar from "./../Sections/Topbar";
import Footer from "./../Sections/Footer";
import { connect } from "react-redux";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FaPowerOff } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { FaUnlockKeyhole } from "react-icons/fa6";
import { AiOutlineUserDelete } from "react-icons/ai";
import { RiCouponLine } from "react-icons/ri";
import { GoCodeReview } from "react-icons/go";
import { IoNotificationsSharp } from "react-icons/io5";
import { SiPrivateinternetaccess } from "react-icons/si";
import { TbAirConditioning } from "react-icons/tb";
import { CiHeart } from "react-icons/ci";
import { IoBagCheckOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";
import { IoMenu } from "react-icons/io5";


import {
  fetchUserDetailsApi,
  logoutApi,
  infoUpdate,
  ProfileChange,
} from "../APIS/AuthApis";
import { AiOutlinePlusCircle } from "react-icons/ai";
import ProfileAddAddress from "../Layouts/ProfileAddAddress";
import { useNavigate } from "react-router-dom";
import CoupanPage from "../Pages/CoupanPage";
import "./../Styles/profile.css";
import {
  setUserAuthentication,
  userLogout,
  setuserDeaitals,
} from "../Redux/actions";
import MyOrder from "./MyOrder";
import OrderDetail from "./OrderDetail";

import Changepaswordmodel from "./Changepaswordmodel";
import DeleteAccountmodel from "./DeleteAccountmodel";
import Notification from "./Notification";
import { MdOutlineMenu } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProfilePage({
  getUserAuthenticationRedux,
  getSelectedLanguage,
  removeAuth,
  getSingleAddress,
  setuserDetailsRedux,
}) {
  let Relove_Token = localStorage.getItem("Relove_Token");
  const { t } = useTranslation();

  const navigation = useNavigate();
  const [profileData, setProfileData] = useState();
  const [profileDataDummy, setProfileDataDummy] = useState();

  const [show, setShow] = useState("profile");
  const handleOpen = () => setOpen(true);
  const [orderId, setorderId] = useState("");
  const [order_id, setorder_id] = useState("");

  const handleOpen1 = () => setOpen1(true);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  // console.log(getSingleAddress)
  //  console.log(profileData.id)
  setuserDetailsRedux(profileData);

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    if (Relove_Token) {
      fetchUserDetailsApi(Relove_Token, (data) => {
        setProfileData(data);
        setProfileDataDummy(data);
      });
    }
    else{
      localStorage.removeItem("Relove_Token");
      
    }
  }, [Relove_Token]);

  const handlesubmit = (e) => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    e.preventDefault();
    let formdata = {
      language: getSelectedLanguage,
      name: profileDataDummy.name_en,
      phone: profileDataDummy.phone,
    };

    infoUpdate(Relove_Token, formdata, profileData, setProfileData);
  };

  const inputRef = useRef(null);
  const handleImage = (e) => {
    const file = e.target.files[0];

    var render = new FileReader();
    render.readAsDataURL(file);
    render.onload = () => {
      let profile = {
        profile: render.result,
      };
      ProfileChange(Relove_Token, profile, (callback) => {
        setProfileData(callback);
      });
    };
    render.onerror = (error) => {
      console.log("wwwwwww", error);
    };
  };
  const handleUpdateImage = () => {
    inputRef.current.click();
  };
  const [profileMenu, setProfileMenu] = useState(false);
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container-fuild my-4 mx-md-5 mx-2 ">
        <div className="row m-0">
          <div className="col-sm-3 profile-slider-left">
            <div className="profile-slider-left-top-div">
              <img
                src={
                  profileData?.image
                    ? profileData?.image
                    : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                }
                alt="profile"
              />
              <h4>{profileData?.name_en}</h4>
              <p>{profileData?.email}</p>

              <button className="" onClick={handleUpdateImage}>
                {t("EDITProfile")}
              </button>
              <input
                type="file"
                accept="image/png"
                ref={inputRef}
                onChange={handleImage}
                style={{ display: "none" }}
              />
            </div>
            <div className="profile-slider-left-Myorder">
              <div className="row d-flex align-items-center ">
                <div className="col-sm-2">
                  <i
                    className="fa fa-download download-icon"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="col-sm-8 p-0 m-0">
                  <p onClick={() => setShow("myorders")}>
                    {t("ORDERSPROFILE")}
                  </p>
                </div>
                <div className="col-sm-2 greater-than">
                  <i className="fa-solid fa-greater-than"></i>
                </div>
              </div>
            </div>
            <div className="profile-slider-left-account-setting">
              <div className="row">
                <div className="col-sm-1">
                  <i className="fa-solid fa-user left-slider-icon"></i>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-9 p-0 account-setting">
                  <p className="account">{t("ACCOUNTSETPRO")}</p>
                  <p onClick={() => setShow("profile")}>
                    {t("PROFILEINFORMATION")}
                  </p>
                  <p onClick={handleOpen}>{t("ChangePassword")}</p>
                  <p onClick={handleOpen1}>{t("DeleteAccount")}</p>
                </div>
              </div>
            </div>
            <div className="profile-slider-left-account-setting">
              <div className="row">
                <div className="col-sm-1">
                  <i className="fa-solid fa-credit-card left-slider-icon"></i>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-9 p-0 account-setting ">
                  <p className="account">{t("MYSTUFF")}</p>
                  <p onClick={() => setShow("coupons")}>{t("MYCoupons")}</p>

                  <p onClick={() => setShow("notification")}>
                    All Notfications
                  </p>
                  <p onClick={() => navigation("/wishlist")}>
                    {t("MYWHISLISTPRO")}
                  </p>
                  <p onClick={() => navigation("/cart")}>{t("MYCARTPRO")}</p>
                </div>
              </div>
            </div>
            <div className="profile-slider-left-account-setting">
              <div className="row">
                <div className="col-sm-1">
                  <i className="fa-solid fa-hammer left-slider-icon"></i>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-9 p-0 account-setting">
                  <p className="account">{t("LEGEL")}</p>
                  <p onClick={() => navigation("/termsuse")}>{t("TERMSOF")}</p>
                  <p onClick={() => navigation("/privacypolicy")}>
                    {t("PRIVACYPRO")}
                  </p>
                </div>
              </div>
            </div>
            <div className="profile-slider-left-logout">
              <div className="row">
                <div className="col-sm-2">
                  <i className="fa fa-arrow-right download-icon"></i>
                </div>
                <div
                  onClick={() => {
                    let Relove_Token = localStorage.getItem("Relove_Token");

                    logoutApi(
                      Relove_Token,
                      navigation,
                      getSelectedLanguage,
                      removeAuth
                    );
                  }}
                  className="col-sm-8 account-setting m-0 p-0"
                >
                  <p>{t("LOGOUT")}</p>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </div>
            <div className="profile-slider-left-frequently">
              <h4>{t("FREQUENTLYPRO")}</h4>
              <p>{t("TRACKHELPPRO")}</p>
            </div>
          </div>
          {/* section    525px profile left side secton */}

          <div className="justify-content-center d-md-none d-flex">
            <div className="profile-slider-left-top-div">
              <img
                src={
                  profileData?.image
                    ? profileData?.image
                    : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                }
                alt="profile"
              />
              <h4>{profileData?.name_en}</h4>
              <p>{profileData?.email}</p>

              <button className="" onClick={handleUpdateImage}>
                {t("EDITProfile")}
              </button>
              <input
                type="file"
                accept="image/png"
                ref={inputRef}
                onChange={handleImage}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div
            className="d-md-none d-flex profilemenu ps-0"
            onClick={() => setProfileMenu(!profileMenu)}
          >
            {" "}
            <IoMenu style={{ fontSize: "40px" }} />  </div>
            {profileMenu === true ? (
              <div className="profilemenu-box ">
              
              <div className="d-flex justify-content-between align-items-center profilemenu-close">
              <div>
                 <img src="/img/logoimage.png" alt="logo" />
              </div>
              {/* <img src="/img/logo.png" alt=""/> */}
              <IoIosClose style={{ fontSize: "40px",cursor:"pointer"}} onClick={() => setProfileMenu(false)}/></div>
              

                <div className="profile-box-heading">
                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => navigation("/")}
                  >
                    {" "}
                    <span className="me-2">
                      <IoHomeOutline />
                    </span>
                    {t("Homecart")}
                  </div>
                  <div
                    className="d-flex align-items-center profilemenu-heading"
                    onClick={() => setShow("myorders")}
                  >
                    <span className="me-2">
                      <AiOutlineUnorderedList />
                    </span>
                    {t("MyOrders")}
                  </div>
                  <div
                    className="d-flex profilemenu-heading align-items-center "
                    onClick={() => setShow("profile")}
                  >
                    {" "}
                    <span className="me-2">
                      <ImProfile />
                    </span>
                    {t("PROFILEINFORMATION")}
                  </div>
                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={handleOpen}
                  >
                    <span className="me-2">
                      <FaUnlockKeyhole />
                    </span>
                    {t("ChangePassword")}
                  </div>
                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={handleOpen1}
                  >
                    {" "}
                    <span className="me-2">
                      <AiOutlineUserDelete />
                    </span>
                    {t("DeleteAccount")}
                  </div>
                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => setShow("coupons")}
                  >
                    {" "}
                    <span className="me-2">
                      <RiCouponLine />
                    </span>
                    {t("MYCoupons")}
                  </div>

                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => setShow("notification")}
                  >
                    {" "}
                    <span className="me-2">
                      <IoNotificationsSharp />
                    </span>
                    All Notification
                  </div>
                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => navigation("/wishlist")}
                  >
                    {" "}
                    <span className="me-2">
                      <CiHeart />
                    </span>
                    {t("MYWHISLISTPRO")}
                  </div>
                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => navigation("/cart")}
                  >
                    {" "}
                    <span className="me-2">
                      <IoBagCheckOutline />
                    </span>
                    {t("MYCARTPRO")}
                  </div>

                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => navigation("/termsuse")}
                  >
                    <span className="me-2">
                      <TbAirConditioning />
                    </span>
                    {t("TERMSOF")}
                  </div>
                  <div
                    className="d-flex align-items-center profilemenu-heading"
                    onClick={() => navigation("/privacypolicy")}
                  >
                    {" "}
                    <span className="me-2">
                      <SiPrivateinternetaccess />
                    </span>
                    {t("PRIVACYPRO")}
                  </div>

                  <div
                    className="d-flex profilemenu-heading align-items-center"
                    onClick={() => {
                      let Relove_Token = localStorage.getItem("Relove_Token");

                      logoutApi(
                        Relove_Token,
                        navigation,
                        getSelectedLanguage,
                        removeAuth
                      );
                    }}
                  >
                    {" "}
                    <span className="me-2">
                      <FaPowerOff />
                    </span>
                    {t("LogOut")}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          {/* </div> */}

          {/* section    525px profile left side secton */}

          <div className="col-sm-9 profile-right " id="prfolie-right-5">
            <div className="row">
              {show === "profile" ? (
                <>
                  <div className="col-md-6" id="profileid">
                    <h5> {t("EditProfile")}</h5>

                    <div className="profile-right-side">
                      <form onSubmit={handlesubmit}>
                        <div className="input-div-profile">
                          <label htmlhtmlFor="Email">{t("EmailAddress")}</label>
                          <input
                            disabled
                            type="text"
                            placeholder={t("EmailAddress")}
                            className=""
                            id="Email"
                            value={profileData?.email}
                          />
                        </div>

                        <div className="input-div-profile">
                          <label htmlhtmlFor="phone">{t("MobileNumber")}</label>
                          <input
                            type="text"
                            placeholder={t("MobileNumber")}
                            className=""
                            id="phone"
                            name="phone"
                            value={profileData?.phone}
                            onChange={(e) =>
                              setProfileData({
                                ...profileData,
                                phone: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="input-div-profile">
                          <label htmlhtmlFor="username">
                            {t("UsernameADD")}
                          </label>
                          <input
                            type="text"
                            placeholder={t("USERNAME")}
                            className=""
                            id="username"
                            name="name_en"
                            value={profileDataDummy?.name_en}
                            onChange={(e) =>
                              setProfileDataDummy({
                                ...profileDataDummy,
                                name_en: e.target.value,
                              })
                            }
                          />
                        </div>

                        <button
                          className="btn text-white d-flex justify-content-center align-items-center w-100 save-detalis"
                          type="submit"
                        >
                          {t("SAVEDETAILS")}
                        </button>
                      </form>
                      <button className="btn text-white d-flex justify-content-center align-items-center w-100  change-password" onClick={handleOpen}>
                        {t("CHANGEPASSWORD")}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5>
                      {" "}
                      {t("AddAddress")} <AiOutlinePlusCircle />
                    </h5>
                    <ProfileAddAddress />
                  </div>
                </>
              ) : (
                ""
              )}
              {show === "coupons" ? <CoupanPage /> : ""}
              {show === "myorders" ? (
                <MyOrder
                  setShow={setShow}
                  setorderId={setorderId}
                  setorder_id={setorder_id}
                />
              ) : (
                ""
              )}
              {show === "track" ? (
                <OrderDetail
                  getSingleAddress={getSingleAddress}
                  getSelectedLanguage={getSelectedLanguage}
                  orderId={orderId}
                  order_id={order_id}
                />
              ) : (
                ""
              )}
              {show === "notification" ? <Notification /> : ""}

              <Changepaswordmodel open={open} setOpen={setOpen} />
              <DeleteAccountmodel
                open1={open1}
                setOpen1={setOpen1}
                email={profileData?.email}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getSelectedLanguage: state.language.language,
    getSingleAddress: state.SingleAddress.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
    removeAuth: () => {
      dispatch(userLogout());
    },
    setuserDetailsRedux: (parameter) => {
      dispatch(setuserDeaitals(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
