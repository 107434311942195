import React,{useState} from "react";
import {AiOutlineCaretDown} from 'react-icons/ai'
import { setSelectedLanguages } from "../Redux/actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./../Styles/topbar.css";

function Topbar({setSelectedLanguagesRedux,getSelectedLanguage}) {
  const { t } = useTranslation();
  const [openLanguage, setOpenLanguage] = useState(false)

  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-6" id="topbarmenu-6">
            <div className="topbar-main-1">
              <span>{t('PhoneTop')}</span>
              <span>|</span>
              <span>{t('EmailTop')}</span>
            </div>
          </div>
          <div className="col-md-4 d-md-flex d-none"></div>
          <div className="col-md-3 col-6" id="topbarmenu-2">
            <div className="topbar-main-2">
              <span>{t('myAc')}</span>
              <span>|</span>
              <span onMouseEnter={()=>setOpenLanguage(true)} onMouseLeave={()=>setOpenLanguage(false)} >{getSelectedLanguage ==='en'? 'English' : 'Arabic'}<AiOutlineCaretDown/></span>
              {
                openLanguage
                ?
                <div onMouseEnter={()=>setOpenLanguage(true)} onMouseLeave={()=>setOpenLanguage(false)} className="langaugae-dropdown">
                <ul>
                  <li onClick={()=>{
                        i18next.changeLanguage("en");
                        localStorage.setItem('language', 'en')
                        setSelectedLanguagesRedux("en");
      // window.location.reload();
                        
                  }} >English</li>
                  <li onClick={()=>{
                        i18next.changeLanguage("ar");
                        localStorage.setItem('language', 'ar')
                        setSelectedLanguagesRedux("ar");
      // window.location.reload();

                  }}>Arbic</li>
                </ul>
            </div>
            :
            null
              }
              <span>|</span>
              <span>{t('AED')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);


