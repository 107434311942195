import React,{useState} from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {DeleteAccount} from "../APIS/AuthApis"
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width:"400",
 

  bgcolor: "background.paper",
  border: "2px solid #AA8C72",

  p: 4,
};


const DeleteAccountmodel = ({ open1, setOpen1}) => {
  const handleClose1 = () => setOpen1(false);
  const [showPassword, setShowPassword] = useState(false);

  const[input,setInput]=useState({
    password:"",
   

  })
  const handleinput=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
        setInput({...input,[name]:value})
  }
  const handleSubmit=(e)=>{
    let Relove_Token = localStorage.getItem("Relove_Token");

    e.preventDefault();
    // console.log(input)
    // DeleteAccount(Relove_Token,input,setOpen1)
  }
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup" >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="d-flex justify-content-between">
             <h5 style={{marginBottom:"20px"}}>{t('DeleteAccount')}</h5>
          <IoMdClose onClick={handleClose1} fontSize={25}/>
          </div>

                <form  onSubmit={handleSubmit}>
                <div className="mb-3">
                <label className="form-label">{t('Password')}</label>
                <div className="" style={{position:"relative"}}>
                  <input
                     type={showPassword?"text":"password"}
                    className="form-control"

                    name="password"
                    value={input.password}
                  onChange={handleinput}
                  />
                  <div style={{position:"absolute",top:"10px",right:"20px"}}>{showPassword?  <AiFillEye onClick={()=>setShowPassword(!showPassword)} />:<AiFillEyeInvisible onClick={()=>setShowPassword(!showPassword)}/>}</div>

                </div>
              </div>
              <div className="d-flex justify-content-end">
              <button className="btn btn-success" type="submit" style={{backgroundColor:"#AA8C72",border:"none"}}>{t('Delete')}</button>
              </div>
              </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAccountmodel;
