import React, { useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getAllProductsHomeApi } from "../APIS/apis";
import "./../Styles/similierproduct.css";
import { useTranslation } from "react-i18next";

function SimilierProducts({setUpdateProductPage, updateProductPage}) {
    const navigation =useNavigate();
  const { t } = useTranslation();
let language=localStorage.getItem("language")
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getAllProductsHomeApi(language,(data) => {
      setProducts([...data[1]?.products,...data[2]?.products]);
    });
  }, []);

  return (
    <div className="similierproduct">
      <div className="container">
        <div className="similierproduct-main">
            <h3>{t('SimilierProducts')}</h3>

          <ul>
            {products &&
              products?.map((item, i) => {

                return (
                  <li key={i} onClick={()=>{
                    navigation(`/product/${item.id}`); 
                    setUpdateProductPage(!updateProductPage)
                    }} >
                    <div className="similierproduct-background-div text-center"  style={{ backgroundImage: `url(${item.featured_image})`, }}>
                     {/* <div className="text-end"> <AiOutlineHeart/></div> */}
                         <span className="text-center">{item?.offerDiscount}% {t('OFF')}</span>
                    </div>
                    <h6>{t('PureCotton')}</h6>
                    <h5 className="text-start">{item?.name?.slice(0,20)}...</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                      {item?.sale_price} {t('AED')}{" "}
                        <span className="discount-price">({item?.base_price}) {t('AED')}</span>
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SimilierProducts;
