import React from "react";
import './../Styles/fashionsale.css'
import { useTranslation } from "react-i18next";


function FashionSale() {
  const { t } = useTranslation();

  return (
    <div className="fashiondale">
      <div className="container">
        <div className="row">
          <div className="col-md-6 fashionsale-background">
            <div className="row">
              <div className="col-md-6">
                <div className="sale-text">
                  <h2>{t('WinterSale')}</h2>
                  <h4>{t('Upfashion')}</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                    distinctio laboriosam obcaecati repudiandae.
                  </p>
                  <button>{t('Shopfashion')}</button>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="sale-image">
                  <img src="/img/girl1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 fashionsale-background-1">
            <div className="row">
              <div className="col-md-6">
                <div className="sale-text">
                  <h2>{t('WinterSale')}</h2>
                  <h4>{t('Upfashion')}</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                    distinctio laboriosam obcaecati repudiandae.
                  </p>
                  <button>{t('Shopfashion')}</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="sale-image">
                  <img src="/img/girl2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FashionSale;
