import React, { useEffect, useState } from "react";

import { CoupanFetch } from "../APIS/CoupanApi";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const CoupanPage = ({ getSelectedLanguage }) => {
  const [DataCoupan, setDataCoupan] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    let fromdata = {
      page: 1,
      language: getSelectedLanguage,
    };

    CoupanFetch(fromdata, (callbackData) => {
      setDataCoupan(callbackData.data.data.offers);
    });
  }, []);
  // console.log(DataCoupan)
  return (
    <>
      <div className="">
        <div
          className="coupancode table-responsive"
          style={{
            width: "100%",
            border: "1px solid rgb(157, 131, 98)",
            padding: "0px 20px",
          }}
        >
          <table className="table">
            <thead className="tablehead">
              <tr style={{ fontSize: "15px" }}>
                <th scope="col">{t("Id")}</th>
                <th scope="col">{t("Name")}</th>
                <th scope="col">{t("Description")}</th>

                <th scope="col">{t("DiscountType")}</th>
                <th scope="col">{t("DiscountPercent")}</th>
                <th scope="col">{t("StartDate")}</th>

                <th scope="col">{t("EndDate")}</th>
              </tr>
            </thead>
            {DataCoupan?.map((item, i) => {
              console.log(item);
              return (
                <tbody>
                  <tr style={{ fontSize: "16px" }}>
                    <th scope="row" key={i}>
                      {item.id}
                    </th>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>{item.discount_type}</td>
                    <td>{item.discount_percent}</td>
                    <td>{item.start_date}</td>
                    <td>{item.end_date}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};
const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(CoupanPage);
