import React,{useState}from "react";
import ModelOtp from "./ModelOtp";
import {ForgetApi} from "../APIS/AuthApis"
import {ErrorToaster} from "../Toaster";
import { useTranslation } from "react-i18next";

const Forgetpassword = () => {
    const[username,setUsername]=useState("")
  const [open1, setOpen1] = React.useState(false);

  const { t } = useTranslation();


const handleSubmit=(e)=>{
    e.preventDefault();
    let fromdata={
      username:username,
    }
   if(username===""){
    ErrorToaster("Enter is valid email")
   }else{
    ForgetApi(fromdata,setOpen1)}

}
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6 col-12 d-none d-sm-flex login-left">
          <img src="/img/singupimage/leftsideiamge.jpg" alt="image" />
        </div>
        <div className="col-sm-6 col-12  d-flex justify-content-center align-items-center">
        <div className="w-100 px-sm-5  mt-sm-none mt-5">
        <h5 className="mb-3 fw-bold">{t('ForgetPassword')}</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">{t('Email')}</label>
              <div className="">
                <input type="email" className="form-control" name="username" value={username} onChange={(e)=>setUsername(e.target.value)} />
              </div>
            </div>
            <div className="mb-3">
                <button type="submit" className="btn btn-success w-100" style={{backgroundColor:"#F1EBE3",border:"#F1EBE3",color:"black"}} > {t('GenerateOtp')}</button>
            </div>
          </form>
          </div>
        </div>
        <ModelOtp open1={open1} setOpen1={setOpen1}/>
      </div>
    </div>
  );
};

export default Forgetpassword;
