import React,{useState, useEffect} from 'react'
import { getParentCategory } from '../APIS/apis'
import { useNavigate } from 'react-router-dom'
import './../Styles/parentcategory.css'

function ParentCategory({getSelectedLanguage}) {
  let language=localStorage.getItem("language")
    //  console.log(language,"kkkkkkkk")
  const navigation =useNavigate()
    const [data,setData] = useState([])

    useEffect(()=>{
        getParentCategory(language,(response)=>{
            setData(response)
        })
    },[language])
    
  return (
   <>
   <div className="parentcategory">
     <div className="container">
        <div className="row py-5 justify-content-center">
           {
            data.map((item,i)=>{
                return(
                    <div onClick={()=>navigation(`/category/${item.id}/${item.name}/categoryName=${item.name}`)} key={i} className="parentcategory-main">
                    <img src={item.image} alt="" />
                     <p>{item.name}</p>
                </div>
                )
            })
           }
        </div>
     </div>
   </div>
   </>
  )
}

export default ParentCategory
