const Citycode = [
  {
    name: "Dubai",
    code: "DXB",
  },
  {
    name: "Abudhabi",
    code: "AUH",
  },
  {
    name: "Sharjah",
    code: "SHJ",
  },
  {
    name: "Ajman",
    code: "AJM",
  },
  {
    name: "Ras Al khaima",
    code: "RAK",
  },
  {
    name: "Fujaiah",
    code: "FUJ",
  },
  {
    name: "Umm Al Quwain",
    code: "UAQ",
  },
  {
    name: "Al Ain",
    code: "ALN",
  },
];
export default Citycode;
