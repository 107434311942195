import { CART_LENGTH } from "../type"


const initialState = {
    length:0
}

const cartLengthReducer= (state = initialState, action) => {

    switch (action.type) {
        case CART_LENGTH: return {
            ...state,
            length: action.payload
        }
        default: return state
    }
}

export default cartLengthReducer;