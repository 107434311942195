import React from 'react';
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { IoMdClose } from "react-icons/io";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  
    width:400,
  
  
    bgcolor: "background.paper",
    border: "2px solid #000",
  
    p: 4,
  };

const EditReview = ({ open1, setOpen1}) => {
  const handleClose1 = () => setOpen1(false);
//   console.log(reviewId)
  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div className="d-flex justify-content-between">
             <h5 style={{marginBottom:"20px"}}>Edit Review</h5>
          <IoMdClose onClick={handleClose1} fontSize={25}/>
          </div>

               
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default EditReview