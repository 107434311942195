import { USER_DETAIL } from "../type"

//initializing state 
const initialState = {
    data: null
}

const userDetailsReducer= (state = initialState, action) => {

    switch (action.type) {
        case USER_DETAIL: return {
            ...state,
            data:action.payload
        }
        default: return state;
    }
}

export default userDetailsReducer;