import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const loginApi = (
  email,
  password,
  navigation,
  setUserAuthenticationRedux
) => {
  let data = JSON.stringify({
    email: email,
    password: password,
    device_token: "d",
    device_id: "d",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(response.data);
      localStorage.setItem("Relove_Token", response.data.data.token);
      setUserAuthenticationRedux(response.data.data.token?.replaceAll('"', ""));
      navigation("/");

    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const registerApi = (formData, navigation) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      navigation("/");
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const fetchUserDetailsApi = (token, callback) => {
  // console.log(token,'token')
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-user`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data.data.user);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addAddressApi = (token, formData,FetchAddressApi,formdata,callback) => {
  let data = JSON.stringify(formData);


  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response.data.message);
      FetchAddressApi(token,formdata,callback)
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const changePassword = (token, formData, setOpen,navigation) => {
  let data = JSON.stringify(formData);
  // console.log(token)
  // console.log(data)

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}password-change`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response.data.message);
      setOpen(false);
      navigation("/login")
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};

export const DeleteAccount = (token, formData, setOpen1) => {
  let data = JSON.stringify(formData);
  // console.log(token)
  // console.log(data)

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseUrl}delete-account`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
     },
    data : data
  };

  axios.request(config)
  .then((response) => {
    SuccessToaster(response.data.message)
  setOpen1(false);
    // console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    ErrorToaster(error?.response?.data?.message);

    // console.log(error);
  });
};
export const FetchOrderlist = (token, formData, callback) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-list`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // SuccessToaster(response.data.message);

      callback(response.data);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
export const logoutApi = (
  token,
  navigation,
  getSelectedLanguage,
  removeAuth
) => {
  let data = JSON.stringify({
    language: getSelectedLanguage,
  });
  // console.log(token)
  // console.log(data)

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}logout`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response.data.message);
      navigation("/");
      removeAuth();
      // console.log(JSON.stringify(response.data));
      localStorage.removeItem("Relove_Token");
      window.location.reload();

    })
    .catch((error) => {
      // ErrorToaster(error?.response?.data?.message);
      console.error(error)
    });
};
export const infoUpdate = (token, formdata, profileData, setProfileData) => {
  let data = JSON.stringify(formdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}info-update`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response.data.message);

      setProfileData({ ...profileData, name_en: formdata.name });
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};
export const ProfileChange = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);
  // console.log(token)
  // console.log(data)

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}profile-update`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response.data.message);
      fetchUserDetailsApi(token, callback);
    })
    .catch((error) => {
      ErrorToaster(error?.response?.data?.message);
    });
};



export const ForgetApi = (formData,setOpen1) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}forgot-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      setOpen1(true)
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        // console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      // console.log(error, "error");
    });
};
export const OtpverifyApi = (formData,navigation) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}otp-verification`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);

      navigation("/Reset-password",{state:{token:response.data.data.token}})
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        // console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      // console.log(error, "error");
    });
};
export const ResetverifyApi = (formData,navigation) => {
  let data = JSON.stringify(formData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}reset-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      navigation("/login")
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        // console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      // console.log(error, "error");
    });
};



