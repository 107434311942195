import {getAuth,FacebookAuthProvider} from "firebase/auth"
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDaaFmAkWsirA_lXE_RTjikba0UXzkS7tg",
    authDomain: "relove-1.firebaseapp.com",
    projectId: "relove-1",
    storageBucket: "relove-1.appspot.com",
    messagingSenderId: "138751178435",
    appId: "1:138751178435:web:5d615019a1f6a0b87c9476",
    measurementId: "G-VJQ6ED1TB9"
  };
const app = initializeApp(firebaseConfig);
const authfacebook=getAuth(app);

const fbAuthProvider= new FacebookAuthProvider();

export{authfacebook,fbAuthProvider}