import {
  ALL_PRODUCTS,
  USER_AUTHENTICATION,
  SELECTED_LANGUAGE,
  CART_LENGTH,
  USER_DETAIL,
  Wish_LENGTH,
  LOGOUT,
  Single_Address,
  User_Deatils
} from "./type";

export const setAllProducts = (parameter) => {
  return {
    payload: parameter,
    type: ALL_PRODUCTS,
  };
};

export const setUserAuthentication = (parameter) => {
  return {
    payload: parameter,
    type: USER_AUTHENTICATION,
  };
};
export const userLogout = () => {
  return {
    type: LOGOUT,
  };
};

export const setUserDetail = (parameter) => {

  return {
    payload: parameter,
    type: USER_DETAIL,
  };
};

export const setSelectedLanguages = (parameter) => {
  return {
    type: SELECTED_LANGUAGE,
    payload: parameter,
  };
};

export const setCartLength = (parameter) => {

  return {
    type: CART_LENGTH,
    payload: parameter,
  };
};
export const setWishLength = (parameter) => {


  return {
    type: Wish_LENGTH,
    payload: parameter,
  };
};
export const setSingleAddress = (parameter) => {
  return {
    type: Single_Address,
    payload: parameter,
  };
};
export const setuserDeaitals = (parameter) => {

  return {
    type: User_Deatils,
    payload: parameter,
  };
};
