import React,{useState,useEffect}from "react";
import SalesTimes from "./SalesTimer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./../Styles/todaypopularsale.css";
import { useTranslation } from "react-i18next";
import { getParentCategory } from '../APIS/apis';
import { useNavigate } from 'react-router-dom'




function TodayPopularSale({getSelectedLanguage}) {
  const { t } = useTranslation();
  let language=localStorage.getItem("language")

    const breakpoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 2},
        { width: 1200, itemsToShow: 4 },
      ];
      const navigation =useNavigate()
    const [data,setData] = useState([])

    useEffect(()=>{
        getParentCategory(language,(response)=>{
            setData(response)
        })
    },[language])


  return (
    <div className="todaypopularsale">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 heading-todaypopularsale text-center">
            <h2>{t('TodayPopular')} <span className="todaypopularsale-sale-text">{t('Sale')}</span></h2>
            <span className="todaypopularsale-dot"></span>
             <span className="todaypopularsale-line"></span>
            <p className="m-0 " >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
  
  <div className="container">
    <div className="row d-flex justify-content-between align-items-center mt-4 ">
      <div className="col-md-6">
      <div className="timer col-md-5">
            <h5 className="p-0 m-0" >{t('End')}</h5>
            <SalesTimes/>
        </div>
      </div>
        <div className="todaypopularsale-view-all text-end pointer col-md-6 ">
           <h5>{t('SeeItems')}</h5>
        </div>
    </div>
  </div>

  <div className="container">
  <div className="todaypopularsale">
        <div className="container ">
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              autoplayTimeout={2000}
              loop
              margin={10}
              nav={false}
              autoplay={true}
              items={5}
              dots={false}
              breakPoints={breakpoints} 
              pagination={true} 
            >
            {
            data.map((item,i)=>{
                return(
                    <div onClick={()=>navigation(`/category/${item.id}/${item.name}/categoryName=${item.name}`)} key={i} className="parentcategory-main">
                    <img src={item?.image} alt="" />
                     <p>{item?.name}</p>
                </div>
                )
            })
           }
              
           
            </OwlCarousel>
          </div>
        </div>
      </div>
  </div>


    </div>
  );
}

export default TodayPopularSale;
