import React from 'react'
import Topbar from '../Sections/Topbar'


import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
     <Topbar/>
   <Navbar/>
   <div className='' style={{border:"1px solid #AA8C72"}}> </div> 

  <div className='container mt-4' style={{minHeight:"600px"}}>
  <p><strong>{t('Aboutbrand')}</strong></p>
  <p>{t('Aboutshopers')} </p>
  <p> {t('Aboutmostimpact')}</p>

  <p><strong>{t('Aboutserve')}</strong></p>
  <p>{t('Aboutoffer')}</p>
  <p>{t('Aboutseevalue')}</p>
  <p><strong>{t('Aboutopertae')}</strong></p>
  <p>{t('Aboutservice')}</p>
  <p>{t('Aboutunique')}</p>
  <p><strong>{t('Aboutface')}</strong></p>
  <p>{t('aboutFeature')}</p>

  <p><strong>{t('AboutPresuasive')}</strong></p>
  <p>{t('AboutConsider')}</p>



  </div>

    <Footer/>
    </>
  )
}

export default AboutUs