import { Single_Address } from "../type"

//initializing state 
const initialState = {
    id:[]
}

const SingleAddressReducer= (state = initialState, action) => {
    

    switch (action.type) {
    
        case Single_Address: return {
            ...state,
            id: action.payload
        }
        default: return state
    }
}

export default SingleAddressReducer;